import { useEffect, useState } from 'react';
import { Layout, AppLogo } from 'components';
import { useTranslation } from 'react-i18next';
import { QuestionnaireSetup, QuestionnaireGeneralImpression } from 'components/questionnaire';
import { useNavigate } from 'react-router-dom';
import { useQuestionnaireContext } from 'modules/questionnaire/hooks/useQuestionnaireContext';

export default function QuestionnaireIntroduction() {
  const [showSetup, setShowSetup] = useState(true);
  const { localeConfig, institutionConfig } = useQuestionnaireContext();
  const { t } = useTranslation('translation', { lng: localeConfig.locale });

  const navigate = useNavigate();

  const canStartQuestionnaire = Boolean(localeConfig?.locale) && Boolean(institutionConfig?.institution?.value);
  const startQuestionnaire = () => {
    if (canStartQuestionnaire) {
      const url = `${localeConfig.locale}/${institutionConfig.institution.value}`;
      navigate(url);
    }
  };
  return (
    <Layout>
      <div
        className="h-screen w-full pt-8 sm:pb-8 overflow-y-auto flex justify-center"
        style={{ alignItems: 'safe center' }}
      >
        <div className="w-full sm:w-container-sm md:w-container-md xl:w-container-xl">
          <div className="w-full flex justify-center ">
            <AppLogo />
          </div>
          {showSetup && (
            <>
              <div className="text-4xl font-bold text-center xs:px-8 mt-12">{t('questionnaire.generic-title')}</div>
              <div className="text-lg text-justify mt-3 mb-2 xs:px-8">{t('questionnaire.generic-description')}</div>
              <div className="text-lg text-justify mt-0 mb-2 xs:px-8">{t('questionnaire.generic-description2')}</div>
              <div className="text-lg text-justify mt-0 mb-2 xs:px-8">{t('questionnaire.generic-description3')}</div>
              <div className="text-lg text-justify mt-0 mb-0 xs:px-8">{t('questionnaire.generic-description4')}</div>
              <div className="text-lg text-left mt-0 mb-6 xs:px-8">{t('questionnaire.generic-description5')}</div>
            </>
          )}
          {!showSetup && <QuestionnaireDescription />}
          <QuestionnaireSetup onNext={() => setShowSetup(false)} isVisible={showSetup} />
          <QuestionnaireGeneralImpression
            onBackClick={() => setShowSetup(true)}
            onStartClick={startQuestionnaire}
            startEnabled={canStartQuestionnaire}
            isVisible={!showSetup}
          />
        </div>
      </div>
    </Layout>
  );
}

function QuestionnaireDescription() {
  const { questionnaire, fetchQuestionnaire } = useQuestionnaireContext();
  useEffect(() => {
    fetchQuestionnaire();
  }, [fetchQuestionnaire]);
  return (
    <>
      <div className="text-4xl font-bold text-center xs:px-8 mt-12">{questionnaire?.title}</div>
      <div className="text-lg text-center mt-3 mb-12 xs:px-8">{questionnaire?.description}</div>
    </>
  );
}
