/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDashboardStats } from 'api/hooks';
import { Paper, Skeleton, Text } from '@mantine/core';
import { BarChart } from '@mantine/charts';
import { useTranslation } from 'react-i18next';
import { dashboardKeys } from 'api/constants';
import _ from 'lodash';
import { ChartTitle } from './components';

export default function InstitutionSectionsStats({ filter, withLegend }) {
  const { stats, isLoading } = useDashboardStats({
    queryKey: dashboardKeys.institutionSections,
    ...filter,
  });

  const { t } = useTranslation();
  const chartData = useMemo(() => {
    if (!_.isEmpty(stats)) {
      const grouped = _.groupBy(stats, 'mainCode');
      const mapped = _.map(_.keys(grouped), (key) => {
        const group = grouped[key];
        const name = _.find(stats, ['mainCode', key])?.mainName;
        const obj = _.transform(
          group,
          (res, i) => {
            res[`${i.secondName}`] = i.total;
            res.name = name;
            return res;
          },
          {},
        );
        return obj;
      });
      return _.sortBy(mapped, ['male', 'female']).reverse();
    }
    return [];
  }, [stats]);

  return (
    <Skeleton visible={isLoading}>
      <Paper shadow="xs" radius="6px" className="px-4 py-6 w-full">
        <div className="flex flex-col space-y-4">
          <ChartTitle title={t('stats.titles.sections')} />
          <BarChart
            h={300}
            data={chartData}
            type="stacked"
            dataKey="name"
            withLegend={withLegend}
            series={[
              { name: 'Satisfăcut', color: 'emerald.5' },
              { name: 'Parțial satisfăcut', color: 'indigo.5' },
              { name: 'Nesatisfăcut', color: 'yellow.4' },
            ]}
            tickLine="y"
            tooltipProps={{
              content: ({ label, payload }) => <ChartTooltip label={label} payload={payload} />,
            }}
          />
        </div>
      </Paper>
    </Skeleton>
  );
}

function ChartTooltip({ label, payload }) {
  if (!payload) return null;
  const total = _.reduce(payload, (sum, entry) => sum + _.get(entry, 'value', 0), 0);
  return (
    <Paper px="md" py="sm" withBorder shadow="md" radius="md">
      <Text fw={500} mb={5}>
        {label}
      </Text>
      {payload.map((item) => {
        const percentage = _.defaultTo((item.value / total) * 100, 0).toFixed(1);
        return (
          <div key={`${item.name}-${label}`} className="flex space-x-2">
            <div className="w-4 h-4 rounded-full" style={{ backgroundColor: item.color }} />
            <span className="text-sm text-gray-500">{item.name}</span>:{' '}
            <span className="text-gray-900 font-bold text-sm">{`${item.value}, ${percentage}%`}</span>
          </div>
        );
      })}
    </Paper>
  );
}
InstitutionSectionsStats.propTypes = {
  filter: PropTypes.object,
  withLegend: PropTypes.bool,
};
InstitutionSectionsStats.defaultProps = {
  filter: {},
  withLegend: false,
};
