/* eslint-disable no-unused-vars */
import { roles } from 'auth/constants';
import { useHasRole } from './useHasRole';

export const dashboardKeys = {
  totalParticipants: 'Q1',
  totalUnsatisfied: 'Q2',
  infoIndices: 'Q3',
  interactionSatisfaction: 'Q4',
  institutionTrustIndices: 'Q5',
  facilitiesIndices: 'Q6',
  institutionSections: 'Q7',
  patientSatisfactionStats: 'Q8',
  unofficialPaymentsStats: 'Q9',
  nonInsurancePayments: 'Q10',
  unofficialPaymentsCategories: 'Q11',
  facilitiesStats: 'Q12',
};

export const clinicKeys = {
  totalParticipants: [{ key: 'Q1' }],
  totalUnsatisfied: [{ key: 'Q2' }],
  infoIndices: [{ key: 'Q3' }],
  interactionSatisfaction: [{ key: 'Q4' }],
  institutionTrustIndices: [{ key: 'Q5' }],
  facilitiesIndices: [{ key: 'Q6' }],
  institutionSections: [{ key: 'Q7' }],
  patientSatisfactionStats: [{ key: 'Q8' }],
  unofficialPaymentsStats: [{ key: 'Q9' }],
  nonInsurancePayments: [{ key: 'Q10' }],
  unofficialPaymentsCategories: [{ key: 'Q11' }],
  facilitiesStats: [{ key: 'Q12' }],
};

export const ampKeys = {
  totalParticipants: [{ key: 'Q1' }],
  totalUnsatisfied: [{ key: 'Q2' }],
  infoIndices: [{ key: 'Q3' }],
  interactionSatisfaction: [{ key: 'Q4' }],
  institutionTrustIndices: [{ key: 'Q5' }],
  facilitiesIndices: [{ key: 'Q6' }],
  institutionSections: [{ key: 'Q7' }],
  patientSatisfactionStats: [{ key: 'Q8' }],
  unofficialPaymentsStats: [{ key: 'Q9' }],
  nonInsurancePayments: [{ key: 'Q10_1' }],
  unofficialPaymentsCategories: [{ key: 'Q11_1' }],
  facilitiesStats: [{ key: 'Q12_1' }],
};

export const adminKeys = {
  totalParticipants: [{ key: 'Q1' }],
  totalUnsatisfied: [{ key: 'Q2' }],
  infoIndices: [{ key: 'Q3' }],
  interactionSatisfaction: [{ key: 'Q4' }],
  institutionTrustIndices: [{ key: 'Q5' }],
  facilitiesIndices: [{ key: 'Q6' }],
  institutionSections: [{ key: 'Q7' }],
  patientSatisfactionStats: [{ key: 'Q8' }],
  unofficialPaymentsStats: [{ key: 'Q9' }],
  nonInsurancePayments: [{ key: 'Q10' }, { key: 'Q10_1' }],
  unofficialPaymentsCategories: [{ key: 'Q11' }, { key: 'Q11_1' }],
  facilitiesStats: [{ key: 'Q12' }, { key: 'Q12_1' }],
  yearStatsEvolution: [{ key: 'Q13' }],
  hospitalizationMode: [{ key: 'Q14' }],
  institutionsTop: [{ key: 'Q15' }],
};

// TODO: Refactor
export const useDashboardKeys = () => {
  // const isAdmin = useHasRole([roles.admin]);
  // const isClinic = useHasRole([roles.clinic]);
  // const isAmp = useHasRole([roles.amp]);
  return adminKeys;
  // if (isAdmin) {
  //   return adminKeys;
  // }
  // if (isClinic) {
  //   return clinicKeys;
  // }
  // if (isAmp) {
  //   return ampKeys;
  // }
  // return {};
};
