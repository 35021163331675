import { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { getToken, setToken, clearTokenData } from 'storage';
import { useAuth, useGetLoggedInUser } from 'api/hooks';
import { useNavigate } from 'react-router-dom';

import { useDisclosure } from '@mantine/hooks';
import AuthModal from 'modules/AuthModal';
import AuthContext from './AuthContext';

const institutionCategories = {
  AMT: 7,
  Clinic: 5,
};
export default function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [authModalOpened, { open: openAuthModal, close: closeAuthModal }] = useDisclosure(false);

  const [stateToken, setStateToken] = useState(getToken());
  const {
    user,
    isLoading: isFetchingUser,
    resetUser,
    refetch: refetchUser,
  } = useGetLoggedInUser({ enabled: Boolean(stateToken), gcTime: Infinity, staleTime: Infinity });

  const { login, isLoading: isLoginLoading } = useAuth({
    onSuccess: (response) => {
      if (!_.isEmpty(response?.token)) {
        setToken(response.token);
        setStateToken(response.token);
        closeAuthModal();
        navigate('/dashboard');
      }
    },
    onError: (e) => {
      console.log('LoginError: ', e);
    },
  });

  const institutionCategory = useMemo(() => {
    if (user?.categoryCode) {
      const categoryCode = parseInt(user.categoryCode, 10);
      return {
        isAMT: categoryCode === institutionCategories.AMT,
        isClinic: categoryCode === institutionCategories.Clinic,
      };
    }
    return { isAMT: false, isClinic: false };
  }, [user]);

  const logout = useCallback(() => {
    clearTokenData();
    setStateToken();
    resetUser();
  }, [resetUser]);

  const authContext = useMemo(
    () => ({
      isLoggedIn: Boolean(user),
      logout,
      login: openAuthModal,
      isLoginLoading: isLoginLoading || isFetchingUser,
      user,
      isFetchingUser,
      refetchUser,
      ...institutionCategory,
    }),
    [user, logout, openAuthModal, isLoginLoading, refetchUser, isFetchingUser, institutionCategory],
  );
  return (
    <AuthContext.Provider value={authContext}>
      {children}
      <AuthModal opened={authModalOpened} close={closeAuthModal} login={login} isLoginLoading={isLoginLoading} />
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
