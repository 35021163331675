/* eslint-disable no-unused-vars */
import { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuestionnaire, useLocations, useInstitutions, useSubmitQuestionnaire } from 'api/hooks';
import _ from 'lodash';
import { parseSectionsDto, getDeviceId } from 'utils';
import QuestionnaireContext from './QuestionnaireContext';

export default function QuestionnaireProvider({ children }) {
  const [questionnaireLocale, setQuestionnaireLocale] = useState(localStorage.getItem('localeCode') ?? 'ro');
  const [questionnaireLocation, setQuestionnaireLocation] = useState();
  const [institution, setInstitution] = useState();
  const [isSubmittedSuccessfully, setSubmittedSuccessfully] = useState(false);
  const [questionnaireGeneralImpression, setQuestionnaireGeneralImpression] = useState();
  const { submitQuestionnaire: submit, isLoading: isSubmitting } = useSubmitQuestionnaire({
    onSuccess: () => {
      setSubmittedSuccessfully(true);
    },
    onError: (e) => {
      console.log('Submit Error: ', e);
      setSubmittedSuccessfully(false);
    },
  });
  const submitQuestionnaire = useCallback(
    (values) => {
      if (!_.isEmpty(values) && !_.isNil(institution?.value)) {
        const payload = {
          DeviceCode: getDeviceId(),
          Satisfaction: questionnaireGeneralImpression.code,
          Sections: parseSectionsDto(values),
        };
        submit({ id: institution?.value, payload });
      }
    },
    [submit, questionnaireGeneralImpression, institution],
  );

  const { locations, isLoading: isLoadingLocations } = useLocations({ locale: questionnaireLocale });
  const { institutions, isLoading: isLoadingInstitutions } = useInstitutions(
    { locale: questionnaireLocale, locationCode: questionnaireLocation?.value },
    { enabled: !_.isNil(questionnaireLocation?.value) },
  );

  const localeConfig = useMemo(
    () => ({
      locale: questionnaireLocale,
      setLocale: setQuestionnaireLocale,
    }),
    [questionnaireLocale, setQuestionnaireLocale],
  );
  const {
    questionnaire,
    isLoading,
    refetch: fetchQuestionnaire,
  } = useQuestionnaire({ id: institution?.value, locale: localeConfig.locale }, { enabled: false });

  const locationConfig = useMemo(
    () => ({
      location: questionnaireLocation,
      setLocation: setQuestionnaireLocation,
    }),
    [questionnaireLocation, setQuestionnaireLocation],
  );
  const institutionConfig = useMemo(
    () => ({
      institution,
      setInstitution,
    }),
    [institution, setInstitution],
  );

  const generalImpression = useMemo(
    () => ({
      generalImpression: questionnaireGeneralImpression,
      setGeneralImpression: setQuestionnaireGeneralImpression,
      impressions: {
        satisfied: {
          code: '1',
          name: 'satisfied',
        },
        neutral: {
          code: '0.5',
          name: 'neutral',
        },
        dissatisfied: {
          code: '0',
          name: 'dissatisfied',
        },
      },
    }),
    [questionnaireGeneralImpression, setQuestionnaireGeneralImpression],
  );
  const questionnaireContext = useMemo(
    () => ({
      questionnaire,
      isLoading,
      institutions,
      isLoadingInstitutions,
      locations,
      isLoadingLocations,
      fetchQuestionnaire,
      localeConfig,
      locationConfig,
      institutionConfig,
      generalImpression,
      isSubmitting,
      submitQuestionnaire,
      isSubmittedSuccessfully,
      setSubmittedSuccessfully,
    }),
    [
      questionnaire,
      isLoading,
      institutions,
      isLoadingInstitutions,
      locations,
      isLoadingLocations,
      fetchQuestionnaire,
      localeConfig,
      locationConfig,
      institutionConfig,
      generalImpression,
      isSubmitting,
      submitQuestionnaire,
      isSubmittedSuccessfully,
      setSubmittedSuccessfully,
    ],
  );

  return <QuestionnaireContext.Provider value={questionnaireContext}>{children}</QuestionnaireContext.Provider>;
}

QuestionnaireProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
