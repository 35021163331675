import PropTypes from 'prop-types';
import { Anchor, Checkbox, Group } from '@mantine/core';
import { useForm } from '@mantine/form';
import Button from 'components/button/Button';
import { IconLock } from 'components/icons';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import TextField from './TextField/TextField';

export default function AuthForm({ onSubmit, onForgotClick, isVisible, isLoading }) {
  const animationClasses = isVisible ? 'animate-fade-right' : 'animate-fade-left animate-reverse hidden';

  const form = useForm({
    initialValues: {
      username: '',
      password: '',
      keepSignedIn: false,
    },
    validate: {
      username: (val) => _.isEmpty(val) || _.size(val) > 50,
      password: (val) => _.isEmpty(val) || _.size(val) < 5,
    },
  });

  const { t } = useTranslation();
  return (
    <div className={`${animationClasses} flex flex-col space-y-6 p-3 w-[448px]`}>
      <span className="text-3xl font-extrabold text-center text-gray-900">{t('titles.auth')}</span>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextField
          radius="0"
          classNames={{
            input:
              'px-3 border-b-0.5 focus:border-b border-t border-l border-r border-slate-300 rounded-tl-md rounded-tr-md data-[error=true]:border-red-500',
          }}
          placeholder={t('placeholders.user')}
          {...form.getInputProps('username')}
        />
        <TextField
          classNames={{
            input:
              'px-3 rounded-bl-md rounded-br-md border-b border-l border-r border-t-0.5 focus:border-t  border-slate-300 data-[error=true]:border-red-500',
          }}
          radius="0"
          placeholder={t('placeholders.enter-password')}
          type="password"
          {...form.getInputProps('password')}
        />

        <div className="py-6 flex justify-between">
          <Checkbox
            className=" text-sm"
            color="emerald.5"
            {...form.getInputProps('keepSignedIn')}
            label={t('labels.keep-logged-in')}
          />
          <Anchor
            component="button"
            type="button"
            size="sm"
            onClick={onForgotClick}
            className="text-indigo-600 text-sm font-base"
          >
            {t('labels.forgot-pass')}
          </Anchor>
        </div>

        <Group justify="center">
          <Button
            radius="xl"
            color="gradient"
            type="submit"
            className="w-full"
            loading={isLoading}
            leftSection={<IconLock className="text-white size-4" />}
          >
            {t('btn.enter')}
          </Button>
        </Group>
      </form>
    </div>
  );
}

AuthForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onForgotClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
};
AuthForm.defaultProps = {
  isVisible: true,
  isLoading: false,
};
