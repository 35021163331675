import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDashboardStats } from 'api/hooks';
import { Paper, Skeleton, Group } from '@mantine/core';
import { RadarChart } from '@mantine/charts';
import _ from 'lodash';
import { ChartTitle, StatsLabelValue } from './components';

export default function FacilitiesStats({ title, queryKey, filter }) {
  const { stats, isLoading } = useDashboardStats({ queryKey, ...filter });
  const chartData = useMemo(() => {
    if (!_.isEmpty(stats)) {
      return _.map(stats, (c) => {
        return { category: c.mainName, rating: c.total ?? 0, shortName: c.mainCode };
      });
    }
    return [];
  }, [stats]);
  return (
    <Skeleton visible={isLoading} className="h-full">
      <Paper shadow="xs" radius="6px" className="px-4 py-6 h-full">
        <div className="flex flex-col space-y-4">
          <ChartTitle title={title} />
          <Group justify="center">
            <div className="w-full ">
              <RadarChart
                h={300}
                data={chartData}
                dataKey="shortName"
                withPolarRadiusAxis
                polarRadiusAxisProps={{
                  domain: [1, 5],
                }}
                series={[{ name: 'rating', color: 'indigo.8', opacity: 0.3 }]}
              />
            </div>

            <div className="w-max space-y-2">
              {_.map(chartData, (item) => {
                return (
                  <div className="flex space-x-2 items-center" key={item.category}>
                    <span className="text-base font-bold text-gray-900">{item.shortName}</span>
                    <StatsLabelValue label={item.category} value={item.rating} />
                  </div>
                );
              })}
            </div>
          </Group>
        </div>
      </Paper>
    </Skeleton>
  );
}

FacilitiesStats.propTypes = {
  filter: PropTypes.object,
  queryKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

FacilitiesStats.defaultProps = {
  filter: {},
};
