import { api as axios, privateApi } from './axios';

export const api = {
  login: (payload) => {
    return privateApi.post('/oauth2/v1/authorize', { ...payload, grant_type: 'password' }).then(({ data }) => data);
  },
  getCurrentUser: () => {
    return privateApi.get(`/Identity/Account`).then(({ data }) => data);
  },
  updateCurrentUser: ({ payload }) => {
    return privateApi.post(`/Identity/Account`, payload).then(({ data }) => data);
  },
  updateCurrentUserPassword: ({ payload }) => {
    return privateApi.post(`/Identity/Account/pswchage`, payload).then(({ data }) => data);
  },
  getUser: ({ id }) => {
    return privateApi.get(`/User/${id}`).then(({ data }) => data);
  },
  getQuestionnaire: ({ id, locale = 'ro' }) => {
    return axios.get(`/FeedBack/${id}/metadata`, { headers: { 'Accept-Language': locale } }).then(({ data }) => data);
  },
  submitQuestionnaire: ({ id, payload }) => {
    return axios.post(`/FeedBack/${id}`, payload).then(({ data }) => data);
  },
  getLocations: () => {
    return axios.get('/Dict/locations').then(({ data }) => data);
  },
  getInstitutions: ({ locationCode, locale = 'ro' }) => {
    const url = locationCode ? `/Dict/${locationCode}/institutions` : `/Dict/${locale}/institutions`;
    return axios.get(url).then(({ data }) => data);
  },
  getGeneralImpressions: () => {
    return privateApi.get(`/dict/items/Satisfaction`).then(({ data }) => data);
  },
  getInstitutionCategory: ({ locale = 'ro' }) => {
    return axios.get(`/Dict/${locale}/items/InstitutionCategory`).then(({ data }) => data);
  },
  getInstitutionSections: ({ locale = 'ro' }) => {
    return axios.get(`/Dict/${locale}/items/MetaDataDepartment`).then(({ data }) => data);
  },
  getAgeGroups: ({ locale = 'ro' }) => {
    return axios.get(`/Dict/${locale}/items/AgesGroup`).then(({ data }) => data);
  },
  getDashboardTrends: ({ queryKey, locale = 'ro', ...rest }) => {
    return privateApi
      .get(`/Reporting/${locale}/dashboard/trend/${queryKey}`, { params: { ...rest } })
      .then(({ data }) => data);
  },
  getDashboardStats: ({ queryKey, locale = 'ro', ...rest }) => {
    return privateApi
      .get(`/Reporting/${locale}/dashboard/${queryKey}`, { params: { ...rest } })
      .then(({ data }) => data);
  },
  getReports: ({ params, page, pageSize = 10 }) => {
    return privateApi
      .get(`/reporting/registrul/feedback`, {
        params,
        headers: {
          'X-PageIndex': page,
          'X-PageSize': pageSize,
        },
      })
      .then(({ data }) => data);
  },
  getReport: (id) => {
    return privateApi.get(`/reporting/registrul/feedback/${id}`).then(({ data }) => data);
  },
  exportReports: ({ type, page, pageSize = 10, params }) => {
    return privateApi.get(`/Reporting/registrul/feedback/export/${type}`, {
      params,
      headers: {
        'X-PageIndex': page,
        'X-PageSize': pageSize,
      },
    });
  },
  send2emailReports: ({ params }) => {
    return privateApi.get(`/Reporting/registrul/feedback/sharing/email`, {
      params,
    });
  },
  getUsers: ({ params, page, pageSize = 10 }) => {
    return privateApi
      .get(`/user`, {
        params,
        headers: {
          'X-PageIndex': page,
          'X-PageSize': pageSize,
        },
      })
      .then(({ data }) => data);
  },
  getRoles: () => {
    return privateApi
      .get(`/role`, {
        headers: {
          'X-PageIndex': 1,
          'X-PageSize': 100,
        },
      })
      .then(({ data }) => data);
  },
  createUser: ({ payload }) => {
    return privateApi.post(`/user`, payload).then(({ data }) => data);
  },
  updateUser: ({ id, payload }) => {
    return privateApi.put(`/user/${id}`, payload).then(({ data }) => data);
  },
  setUserPassword: ({ id, payload }) => {
    return privateApi.put(`/user/${id}/password`, payload).then(({ data }) => data);
  },
  getNodes: ({ params, page, pageSize = 10 }) => {
    const { parent = 0, ...rest } = params;
    return privateApi
      .get(`/nodes/institution`, {
        params: { Parent: parent, ...rest },
        headers: {
          'X-PageIndex': page,
          'X-PageSize': pageSize,
        },
      })
      .then(({ data }) => data);
  },
  createNode: ({ payload }) => {
    return privateApi.post(`/nodes/institution`, payload).then(({ data }) => data);
  },
  updateNode: ({ id, payload }) => {
    return privateApi.put(`/nodes/institution/${id}`, payload).then(({ data }) => data);
  },
  getNode: ({ id }) => {
    return privateApi.get(`/nodes/institution/${id}`).then(({ data }) => data);
  },
  getQuestionnaires: ({ params, page, pageSize = 10 }) => {
    return privateApi
      .get(`/metadata`, {
        params,
        headers: {
          'X-PageIndex': page,
          'X-PageSize': pageSize,
        },
      })
      .then(({ data }) => data);
  },
  getQuestionnaireMetadata: ({ id }) => {
    return privateApi.get(`/metadata/${id}`).then(({ data }) => data);
  },
  getDictionary: ({ dictionary }) => {
    return privateApi.get(`/Dict/items/${dictionary}`).then(({ data }) => data);
  },
  getDictionaries: () => {
    return privateApi.get(`/Dict/items/types`).then(({ data }) => data);
  },
  createQuestionnaire: ({ payload }) => {
    return privateApi.post(`/metadata`, payload).then(({ data }) => data);
  },
  updateQuestionnaire: ({ id, payload }) => {
    return privateApi.put(`/metadata/${id}`, payload).then(({ data }) => data);
  },
  getTranslations: ({ params, page, pageSize = 100 }) => {
    return privateApi
      .get(`/translate`, {
        params,
        headers: {
          'X-PageIndex': page,
          'X-PageSize': pageSize,
        },
      })
      .then(({ data }) => data);
  },
  getTranslation: ({ resourceKey, key }) => {
    return privateApi.get(`/translate/${resourceKey}/${key}`).then(({ data }) => data);
  },
  publicTranslations: (locale = 'ro') => {
    return axios.get(`Locales/${locale}/translation.json`).then(({ data }) => data);
  },
  downloadDocument: () => {
    return axios.get('/FeedBack/file/a.pdf');
  },
  downloadFile: ({ fileName }) => {
    return axios.get(`/FeedBack/file/${fileName}`);
  },
  updateTranslation: ({ resourceKey, key, payload }) => {
    return privateApi.put(`/translate/${resourceKey}/${key}`, payload).then(({ data }) => data);
  },
  uploadFile: ({ payload }) => {
    const fileBlob = new Blob([payload], { type: 'application/octet-stream' });
    return privateApi
      .post(`/File/Upload`, fileBlob, {
        headers: {
          'Content-Type': 'application/octet-stream',
          'Content-Disposition': `attachment; filename=${payload.name}`,
        },
      })
      .then(({ data }) => data);
  },
};
