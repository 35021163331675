/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDashboardStats } from 'api/hooks';
import { Paper, Skeleton, HoverCard } from '@mantine/core';
import { DonutChart } from '@mantine/charts';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { ChartTitle, StatsLabelValue } from './components';

export default function HospitalizationMethodStats({ title, queryKey, filter, withLegend }) {
  const { stats, isLoading } = useDashboardStats({
    queryKey,
    ...filter,
  });
  const { t } = useTranslation();

  const chartData = useMemo(() => {
    if (!_.isEmpty(stats)) {
      const colors = [
        { color: 'indigo.5', legendColor: 'bg-indigo-500' },
        { color: 'yellow.4', legendColor: 'bg-yellow-400' },
      ];
      return _.map(stats, (item, index) => {
        return { name: item.mainName, value: item.total, ...colors[index], tooltip: 'teeste' };
      });
    }
    return [];
  }, [stats]);

  const formatTooltipValue = useCallback(
    (value) => {
      const total = chartData.reduce((sum, entry) => sum + entry.value, 0);
      const percentage = _.defaultTo((value / total) * 100, 0).toFixed(1);
      return `${value}, ${percentage}%`;
    },
    [chartData],
  );
  return (
    <Skeleton visible={isLoading} className="h-full">
      <Paper shadow="xs" radius="6px" className="px-4 py-6 w-full h-full">
        <div className="flex flex-col space-y-4">
          <ChartTitle title={title} />
          <div className="flex flex-col justify-center items-center">
            <HoverCard width={280} shadow="md" position="top">
              <HoverCard.Target>
                <DonutChart
                  data={chartData}
                  size={200}
                  thickness={50}
                  valueFormatter={formatTooltipValue}
                  withTooltip={false}
                />
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <div className="flex flex-col space-y-2 w-[250px] xs:w-max">
                  {_.map(chartData, (item) => {
                    return (
                      <div className="flex space-x-2 items-center" key={item.name}>
                        <div className={`w-4 h-4 flex-none rounded-full ${item.legendColor}`} />
                        <StatsLabelValue label={item.name} value={formatTooltipValue(item.value)} />
                      </div>
                    );
                  })}
                </div>
              </HoverCard.Dropdown>
            </HoverCard>
            {withLegend && (
              <div className="flex flex-col space-y-2 mt-8">
                <StatsLabelValue label={t('stats.total')} value={stats?.satisfactionStats?.total} />
                {_.map(chartData, (item) => {
                  return (
                    <div className="flex space-x-2 items-center" key={item.name}>
                      <div className={`w-4 h-4 flex-none rounded-full ${item.legendColor}`} />
                      <StatsLabelValue label={item.name} value={formatTooltipValue(item.value)} />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </Paper>
    </Skeleton>
  );
}

HospitalizationMethodStats.propTypes = {
  filter: PropTypes.object,
  queryKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  withLegend: PropTypes.bool,
};

HospitalizationMethodStats.defaultProps = {
  filter: {},
  withLegend: false,
};
