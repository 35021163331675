/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo } from 'react';
import { Modal, Loader } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useQuestionnaireMetadata } from 'api/hooks';
import { useTranslation } from 'react-i18next';
import { VisibilityWrapper } from 'components/visibility-wrapper';
import QuestionnaireDetails from './QuestionnaireDetails';
import QuestionnaireForm from './QuestionnaireForm';

export default function QuestionnaireModal({ opened, onClose, questionnaire, onSubmit, isSubmitting }) {
  const isMobile = useMediaQuery('(max-width: 50em)');
  const { t } = useTranslation();
  const isCreateMode = useMemo(() => !questionnaire?.id, [questionnaire]);
  const { questionnaire: metadata, isLoading } = useQuestionnaireMetadata(questionnaire?.id, {
    enabled: Boolean(questionnaire?.id),
  });

  const initialVisibleComponent = useMemo(() => {
    return !questionnaire?.id ? 'form' : 'details';
  }, [questionnaire]);

  const [visibleComponent, setVisibleComponent] = useState(initialVisibleComponent);
  const modalTitle = useMemo(() => {
    const formVisible = visibleComponent === 'form';
    if (formVisible) {
      return isCreateMode ? t('titles.create-questionnaire') : t('titles.edit-questionnaire');
    }
    return t('titles.questionnaire-details');
  }, [visibleComponent, isCreateMode, t]);

  useEffect(() => {
    setVisibleComponent(questionnaire ? 'details' : 'form');
  }, [questionnaire]);

  return (
    <Modal
      size="90%"
      opened={opened}
      fullScreen={isMobile}
      classNames={{ title: 'text-lg font-bold' }}
      onClose={() => {
        onClose();
        setVisibleComponent(initialVisibleComponent);
      }}
      centered
      keepMounted={false}
      title={modalTitle}
    >
      <div className="p-4">
        {isLoading && (
          <div className="flex justify-center items-center p-40">
            <Loader color="indigo.6" />
          </div>
        )}
        {!isLoading && (
          <VisibilityWrapper value={visibleComponent}>
            <QuestionnaireDetails
              componentKey="details"
              questionnaire={{ ...(questionnaire ?? {}), ...(metadata ?? {}) }}
              onEditClick={() => setVisibleComponent('form')}
            />
            <QuestionnaireForm
              componentKey="form"
              onBackClick={() => setVisibleComponent('details')}
              questionnaire={{ ...(questionnaire ?? {}), ...(metadata ?? {}) }}
              onSubmit={onSubmit}
              isSubmitting={isSubmitting}
            />
          </VisibilityWrapper>
        )}
      </div>
    </Modal>
  );
}
QuestionnaireModal.propTypes = {
  opened: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  questionnaire: PropTypes.object,
};
QuestionnaireModal.defaultProps = {
  opened: false,
  isSubmitting: false,
  onClose: _.noop,
  onSubmit: _.noop,
  questionnaire: null,
};
