import axios from 'axios';
import { getToken } from 'storage';

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const privateApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_PRIVATE,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

privateApi.interceptors.request.use((config) => {
  const token = getToken();
  const storedLocale = localStorage.getItem('localeCode');
  // eslint-disable-next-line no-param-reassign
  config.headers['Accept-Language'] = storedLocale;
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});
