/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SegmentedTabs, ReportsTable, ReportDetails } from 'components';
import {
  InstitutionFilter,
  InstitutionCategoryFilter,
  AgeGroupFilter,
  GeneralImpressionsFilter,
  GenderFilter,
  LocationFilter,
} from 'components/filters';
import { useReports, useExportReports, useSend2EmailReports } from 'api/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { Pagination, Modal, Group, Button, Menu, Text } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconFileTypeXls, IconFileTypePdf, IconDownload, IconMailUp } from '@tabler/icons-react';
import { downloadFile, useStateDisclosure } from 'utils';

export default function Reports() {
  const { page } = useParams();
  const [modalOpened, modalHandler] = useStateDisclosure(false);
  const [modalSend2emailNotifyOpened, modalHandlerSend2emailNotify] = useDisclosure(false);

  const { exportReports, isLoading: isDownloading } = useExportReports({
    onSuccess: (response) => {
      downloadFile(response, 'Raport');
    },
  });
  const { send2emailReports, isLoading: isSend2email } = useSend2EmailReports({
    onSuccess: (response) => {
      modalHandlerSend2emailNotify.toggle();
    },
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (!page || page < 1) {
      navigate('/reports/1', { replace: true });
    }
  }, [page, navigate]);
  const { t } = useTranslation();
  const [locationFilter, setLocationFilter] = useState();
  const [institutionFilter, setInstitutionFilter] = useState();
  const [institutionCategoryFilter, setInstitutionCategoryFilter] = useState();
  const [ageGroupFilter, setAgeGroupFilter] = useState();
  const [generalImpressionFilter, setGeneralImpressionFilter] = useState();
  const [genderFilter, setGenderFilter] = useState();
  const [filter, setFilter] = useState({});
  const [periodFilter, setPeriodFilter] = useState({});
  useEffect(() => {
    const updatedFilter = {};
    if (locationFilter) {
      updatedFilter.Location = locationFilter.item.id;
    }
    if (institutionFilter) {
      updatedFilter.Institution = institutionFilter.item.id;
    }
    if (institutionCategoryFilter) {
      updatedFilter.InstitutionCategory = institutionCategoryFilter.item.id;
    }
    if (ageGroupFilter) {
      updatedFilter.AgeGroup = ageGroupFilter.item.id;
    }
    if (generalImpressionFilter) {
      updatedFilter.Satisfaction = generalImpressionFilter.item.id;
    }
    if (genderFilter) {
      updatedFilter.Sex = genderFilter.item.id;
    }
    if (periodFilter?.startDate) {
      updatedFilter.DateFrom = periodFilter.startDate;
    }
    if (periodFilter?.endDate) {
      updatedFilter.DateTo = periodFilter.endDate;
    }
    setFilter(updatedFilter);
  }, [
    periodFilter,
    locationFilter,
    institutionCategoryFilter,
    ageGroupFilter,
    generalImpressionFilter,
    genderFilter,
    institutionFilter,
  ]);

  const { reports, isLoading } = useReports({ page, params: filter });
  const totalPages = useMemo(() => Math.ceil(_.divide(reports?.count, reports?.pageSize)), [reports]);

  const onReportClick = useCallback(
    (report) => {
      modalHandler.open(report.id);
    },
    [modalHandler],
  );
  const onDownloadClick = (type) => {
    exportReports({ type, page, params: filter });
  };

  const onSend2EmailClick = () => {
    send2emailReports({ params: filter });
  };
  const isMobile = useMediaQuery('(max-width: 50em)');

  const onPeriodChange = useCallback((range) => {
    setPeriodFilter(range);
  }, []);

  return (
    <div className="w-full bg-gray-100 ">
      <Modal size="80%" opened={modalOpened} fullScreen={isMobile} onClose={modalHandler.close} centered>
        <ReportDetails id={modalHandler.state} />
      </Modal>
      <Modal
        opened={modalSend2emailNotifyOpened}
        onClose={modalHandlerSend2emailNotify.close}
        title={t('messages.send2email.title')} 
        className='text-center'               
      >
         <div className="flex-none w-full place-items-center rounded rounded-xl size-[72px] p-2 mb-4">
         <IconMailUp className="w-full text-sky-800  h-full" />
       </div>       
        <Text size="sm">{t('messages.send2email.body')}</Text>
        <Text className="text-sky-800 mt-4" size="sm">{t('messages.send2email.Description')}</Text>
      </Modal>
      <div className="px-4 bg-white shadow">
        <Group justify="space-between" className="py-4">
          <span className="text-lg font-medium">{t('pages.reports')}</span>
          <SegmentedTabs onChange={onPeriodChange} />
        </Group>
        <div className="flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0 sm:justify-end  pb-4">
          <LocationFilter placeholder={t('placeholders.location')} onChange={setLocationFilter} clearable />
          <InstitutionCategoryFilter
            placeholder={t('placeholders.institution-category')}
            onChange={setInstitutionCategoryFilter}
            clearable
          />
          <InstitutionFilter
            placeholder={t('placeholders.institution')}
            onChange={setInstitutionFilter}
            location={locationFilter?.item?.id}
            clearable
          />
          <AgeGroupFilter placeholder={t('placeholders.age-group')} onChange={setAgeGroupFilter} clearable />
          <GeneralImpressionsFilter
            placeholder={t('placeholders.general-impression')}
            onChange={setGeneralImpressionFilter}
            clearable
          />
          <GenderFilter placeholder={t('placeholders.gender')} onChange={setGenderFilter} clearable />
        </div>
        <div className="flex justify-end pb-4">
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                className="w-min"
                variant="outline"
                color="gray.5"
                radius="xl"
                leftSection={<IconDownload className="size-22" />}
                loading={isDownloading || isSend2email}
              >
                {t('btn.download')}
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                className="text-gray-600"
                onClick={() => {
                  onDownloadClick('xlsx');
                }}
                leftSection={<IconFileTypeXls className="size-22" />}
              >
                Xlsx
              </Menu.Item>
              <Menu.Item
                className="text-gray-600"
                onClick={() => {
                  onDownloadClick('pdf');
                }}
                leftSection={<IconFileTypePdf className="size-22" />}
              >
                PDF
              </Menu.Item>
              <Menu.Item
                className="text-gray-600"
                onClick={() => {
                  onSend2EmailClick();
                }}
                leftSection={<IconMailUp className="size-22" />}
              >
                {t('btn.send2email')}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
      </div>
      <div className="p-6">
        <ReportsTable data={reports?.data ?? []} isLoading={isLoading} onRowClick={onReportClick} />
        <div className="flex justify-end py-4">
          <Pagination
            total={totalPages}
            color="gray.3"
            classNames={{
              control:
                'data-[active=true]:border-2 data-[active=true]:border-indigo-600 data-[active=true]:bg-indigo-100 data-[active=true]:text-indigo-600 text-gray-900',
            }}
            autoContrast
            onChange={(value) => {
              navigate(`/reports/${value}`);
            }}
          />
        </div>
      </div>
    </div>
  );
}
