import { SideBarItem } from 'components/sidebar-item';
import { IconHome, IconUsers, IconBuildings, IconDocuments, IconNewspaper, IconReports } from 'components/icons';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useHasRole } from 'auth/hooks/useHasRole';
import { roles } from 'auth';

export default function SideBar() {
  const { t } = useTranslation();
  const isAdmin = useHasRole([roles.admin]);

  const navItems = useMemo(() => {
    const adminItems = [
      { link: '/dashboard', label: t('pages.dashboard'), icon: IconHome },
      { link: '/users', label: t('pages.users'), icon: IconUsers },
      { link: '/institutions', label: t('pages.institutions'), icon: IconBuildings },
      { link: '/questionnaires', label: t('pages.questionnaires'), icon: IconDocuments },
      { link: '/content', label: t('pages.content'), icon: IconNewspaper },
      { link: '/reports', label: t('pages.reports'), icon: IconReports },
    ];
    const defaultItems = [
      { link: '/dashboard', label: t('pages.dashboard'), icon: IconHome },
      { link: '/reports', label: t('pages.reports'), icon: IconReports },
    ];
    return isAdmin ? adminItems : defaultItems;
  }, [t, isAdmin]);
  return (
    <div className="flex flex-col space-y-1">
      {navItems.map((navItem) => (
        <SideBarItem key={navItem.link} {...navItem} />
      ))}
    </div>
  );
}
