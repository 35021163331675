import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useGetPublicTranslation } from 'api/hooks';
import _ from 'lodash';

export default function LinksContainer({ className }) {
  const { translation: title } = useGetPublicTranslation({ resourceKey: 'Landing', key: 'LinksTitle' });

  const { translation: linksTitles } = useGetPublicTranslation({ resourceKey: 'Landing', key: /^Link\[\d+\]Title$/ });
  const { translation: linksLinks } = useGetPublicTranslation({ resourceKey: 'Landing', key: /^Link\[\d+\]Link$/ });

  return (
    <div
      className={classNames(
        className,
        'flex flex-col space-y-8 md:space-y-0 md:flex-row bg-white items-start md:justify-between md:py-24 py-12',
      )}
    >
      <span className="md:px-8 text-3xl font-extrabold sm:w-1/3">{title?.text}</span>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:px-8">
        {_.map(linksTitles, (linkTitle) => {
          const link = _.find(linksLinks, ['key', _.replace(linkTitle.key, 'Title', 'Link')]);
          return <LinkItem key={linkTitle?.text} title={linkTitle?.text} link={link?.text} />;
        })}
      </div>
    </div>
  );
}

function LinkItem({ title, link }) {
  return (
    <div className="flex flex-col space-y-2">
      <span className="text-lg font-base">{title}</span>
      <a className="text-indigo-600 underline" href={link}>
        {link}
      </a>
    </div>
  );
}
LinkItem.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
};
LinkItem.defaultProps = {
  title: '',
  link: '',
};
LinksContainer.propTypes = {
  className: PropTypes.string,
};
LinksContainer.defaultProps = {
  className: '',
};
