/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useForm, isEmail, hasLength, isNotEmpty } from '@mantine/form';
import {
  Button,
  Group,
  TextInput,
  Textarea,
  Paper,
  ActionIcon,
  Divider,
  Select,
  PillsInput,
  Pill,
  Checkbox,
  Collapse,
  UnstyledButton,
  Table,
} from '@mantine/core';
import { LocationSelect } from 'components/fields';
import { IconArrowLeft, IconPlus, IconTrash, IconX, IconChevronDown } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { randomId, useDisclosure } from '@mantine/hooks';
import classNames from 'classnames';
import { useDictionaries, useDictionary } from 'api/hooks';

const uiHintOptions = [
  {
    value: `RadioGroup`,
    label: 'Selecție unică (RadioButton)',
    hasOptions: true,
  },
  {
    value: `CheckBox`,
    label: 'Răspunsuri multiple (Checkbox)',
    hasOptions: true,
  },
  {
    value: `DropDownBox`,
    label: 'Listă de răspunsuri (Dropdown)',
    hasOptions: true,
  },
  {
    value: `Rating`,
    label: 'Notă (1-5)',
    hasOptions: false,
  },
  {
    value: `TextField`,
    label: 'Câmp de text',
    hasOptions: false,
  },
];
const errorClasses =
  ' data-[error=true]:border-red-500  data-[error=true]:border-red-500 data-[error=true]:focus:border-red-500 data-[error=true]:focus:ring-red-500';

export default function QuestionnaireForm({ questionnaire, onSubmit, isSubmitting, onBackClick, visibilityClasses }) {
  const { t } = useTranslation();
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      title: questionnaire?.title ?? '',
      title_ru: questionnaire?.title_ru ?? '',
      description: questionnaire?.description ?? '',
      description_ru: questionnaire?.description_ru ?? '',
      sections: questionnaire?.sections ?? [],
    },
    validate: {
      title: hasLength({ min: 3, max: 255 }, 'Titlul trebuie să conțină de la 3 la 255 caracteree'),
      title_ru: hasLength({ min: 3, max: 255 }, 'Titlul trebuie să conțină de la 3 la 255 caracteree'),
      description: hasLength({ min: 0, max: 255 }, 'Descrierea nu poate avea mai mult de 255 caractere'),
      description_ru: hasLength({ min: 0, max: 255 }, 'Descrierea nu poate avea mai mult de 255 caractere'),
      sections: {
        name: hasLength({ min: 3, max: 255 }, 'Titlul trebuie să conțină de la 3 la 255 caracteree'),
        name_ru: hasLength({ min: 3, max: 255 }, 'Titlul trebuie să conțină de la 3 la 255 caractere'),
        fields: {
          name: hasLength({ min: 3, max: 255 }, 'Titlul trebuie să conțină de la 3 la 255 caractere'),
          name_ru: hasLength({ min: 3, max: 255 }, 'Titlul trebuie să conțină de la 3 la 255 caractere'),
        },
      },
    },
    transformValues: (values) => {
      const addHintSourceCode = (sources) => {
        return _.map(sources, (hint, index) => ({ ...hint, code: `h${index}` }));
      };
      const addFieldsCode = (sectionCode, fields) => {
        return _.map(fields, (field, index) => {
          return _.omit(
            {
              ...field,
              code: field?.code ?? `${sectionCode}.f${index}`,
              uiHintSource: addHintSourceCode(field.uiHintSource),
            },
            ['key'],
          );
        });
      };
      const updatedSections = _.map(values.sections, (section, index) => {
        const sectionCode = section.code ?? `s${index}`;
        return _.omit({ ...section, code: sectionCode, fields: addFieldsCode(sectionCode, section.fields) }, ['key']);
      });
      return { ...values, sections: updatedSections };
    },
  });

  const onAddSectionClick = useCallback(() => {
    form.insertListItem('sections', { key: randomId(), name: '', description: '', fields: [] });
  }, [form]);
  const isCreateMode = useMemo(() => !questionnaire, [questionnaire]);
  return (
    <div className={`p-4 ${visibilityClasses}`}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Group grow>
          <TextInput
            classNames={{
              input:
                'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
            }}
            label={t('labels.title')}
            placeholder={t('labels.title')}
            withAsterisk
            {...form.getInputProps('title')}
          />
          <TextInput
            classNames={{
              input:
                'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
            }}
            label={`${t('labels.title')} (RU)`}
            placeholder={`${t('labels.title')} (RU)`}
            withAsterisk
            {...form.getInputProps('title_ru')}
          />
        </Group>
        <Group grow>
          <TextInput
            classNames={{
              input:
                'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
            }}
            label={t('labels.description')}
            placeholder={t('labels.description')}
            mt="md"
            {...form.getInputProps('description')}
          />
          <TextInput
            classNames={{
              input:
                'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
            }}
            label={`${t('labels.description')} (RU)`}
            placeholder={`${t('labels.description')} (RU)`}
            mt="md"
            {...form.getInputProps('description_ru')}
          />
        </Group>

        <div className="flex flex-col space-y-4 mt-4">
          {form.getValues().sections.map((item, index) => (
            <QuestionnaireSectionForm key={item.key || item.code} form={form} index={index} />
          ))}
        </div>
        <div className="flex justify-center items-center py-4">
          <Button
            variant="outline"
            leftSection={<IconPlus size={16} />}
            radius="xl"
            color="gray.5"
            onClick={onAddSectionClick}
          >
            {t('btn.section-add')}
          </Button>
        </div>

        <Group justify={isCreateMode ? 'flex-end' : 'space-between'} className="mt-7">
          {!isCreateMode && (
            <Button
              variant="outline"
              leftSection={<IconArrowLeft size={16} />}
              radius="xl"
              color="gray.5"
              onClick={onBackClick}
            >
              {t('btn.back')}
            </Button>
          )}
          <Button radius="xl" color="indigo.6" type="submit" loading={isSubmitting}>
            {t('btn.apply')}
          </Button>
        </Group>
      </form>
    </div>
  );
}
QuestionnaireForm.propTypes = {
  questionnaire: PropTypes.object,
  onSubmit: PropTypes.func,
  onBackClick: PropTypes.func,
  isSubmitting: PropTypes.bool,
  visibilityClasses: PropTypes.string,
};
QuestionnaireForm.defaultProps = {
  questionnaire: null,
  onSubmit: _.noop,
  onBackClick: _.noop,
  isSubmitting: false,
  visibilityClasses: '',
};

function QuestionnaireSectionForm({ form, index }) {
  const { t } = useTranslation();
  const [opened, { toggle }] = useDisclosure(false);

  const onAddQuestionClick = useCallback(() => {
    form.insertListItem(`sections.${index}.fields`, {
      key: randomId(),
      name: '',
      name_ru: '',
      description: '',
      description_ru: '',
      placeholder: '',
      placeholder_ru: '',
      uiHintSource: [],
      ui_hint: '',
      dictionary: '',
      is_required: false,
    });
  }, [form, index]);
  return (
    <Paper withBorder p="md" shadow="md">
      <div className="flex flex-col">
        <div className="flex w-full">
          <UnstyledButton className="flex w-full items-center" onClick={toggle}>
            <div className="flex space-x-2 w-full justify-between items-center mr-4">
              <span className="text-gray-900 font-semibold text-base">
                {t('titles.section-title', { number: index + 1 })}
              </span>
              <IconChevronDown
                className={classNames('size-4 transform transition-transform duration-300', { 'rotate-180': opened })}
              />
            </div>
          </UnstyledButton>
          <div>
            <ActionIcon color="gray.1" onClick={() => form.removeListItem('sections', index)}>
              <IconTrash className="size-4 text-gray-900" />
            </ActionIcon>
          </div>
        </div>
        <Collapse in={opened}>
          <div className="px-4">
            <Group grow>
              <TextInput
                classNames={{
                  input:
                    'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
                }}
                label={t('labels.title')}
                placeholder={t('placeholders.section-title')}
                withAsterisk
                mt="md"
                {...form.getInputProps(`sections.${index}.name`)}
              />
              <TextInput
                classNames={{
                  input:
                    'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
                }}
                label={`${t('labels.title')} (RU)`}
                placeholder={`${t('placeholders.section-title')} (RU)`}
                withAsterisk
                mt="md"
                {...form.getInputProps(`sections.${index}.name_ru`)}
              />
            </Group>

            <Group grow>
              <Textarea
                classNames={{
                  input:
                    'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
                }}
                label={t('labels.description')}
                placeholder={t('placeholders.section-description')}
                mt="md"
                {...form.getInputProps(`sections.${index}.description`)}
              />

              <Textarea
                classNames={{
                  input:
                    'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
                }}
                label={`${t('labels.description')} (RU)`}
                placeholder={`${t('placeholders.section-description')} (RU)`}
                mt="md"
                {...form.getInputProps(`sections.${index}.description_ru`)}
              />
            </Group>
            <div className="flex flex-col space-y-4 mt-4">
              <Divider />
              <div className="flex space-x-2 justify-between">
                <span className="text-gray-900 font-semibold text-base">{t('titles.questions-title')}</span>
                <ActionIcon color="gray.1" onClick={onAddQuestionClick}>
                  <IconPlus className="size-4 text-gray-900" />
                </ActionIcon>
              </div>
            </div>
            <div className="flex flex-col space-y-4 mt-4">
              {form.getValues().sections?.[index] &&
                form
                  .getValues()
                  .sections[
                    index
                  ]?.fields?.map((item, qIndex) => <QuestionForm key={item.key ?? item.code} form={form} index={qIndex} sectionIndex={index} />)}
            </div>
          </div>
        </Collapse>
      </div>
    </Paper>
  );
}

function QuestionForm({ form, sectionIndex, index }) {
  const { t } = useTranslation();
  // const [responseOption, setResponseOption] = useState('');
  // const [responseOptionRu, setResponseOptionRu] = useState('');
  const { dictionary, isLoading } = useDictionary(form.getValues().sections[sectionIndex].fields[index].dictionary);
  const { dictionaries, isLoading: loadingDictionaries } = useDictionaries();

  const onAddOptionClick = useCallback(
    (opt) => {
      const currentOptions = form.getValues().sections[sectionIndex]?.fields[index].uiHintSource ?? [];
      const duplicated = _.some(currentOptions, { name: opt.name });
      const duplicatedRu = _.some(currentOptions, { name_ru: opt.name_ru });
      if (!duplicated && !duplicatedRu) {
        form.insertListItem(`sections.${sectionIndex}.fields.${index}.uiHintSource`, opt);
      }
    },
    [form, index, sectionIndex],
  );

  const removeResponseOption = useCallback(
    (i) => {
      form.removeListItem(`sections.${sectionIndex}.fields.${index}.uiHintSource`, i);
    },
    [form, index, sectionIndex],
  );

  const showOptions = useMemo(() => {
    const selectedHint = form.getValues().sections[sectionIndex].fields[index].ui_hint;
    const hintObject = _.find(uiHintOptions, ['value', selectedHint]);
    return hintObject?.hasOptions;
  }, [form, sectionIndex, index]);

  const optionsEditDisabled = useMemo(() => {
    const selectedDictionary = form.getValues().sections[sectionIndex].fields[index].dictionary;
    return Boolean(selectedDictionary) && !_.isEmpty(selectedDictionary);
  }, [form, sectionIndex, index]);

  const options = useMemo(() => {
    if (dictionary) {
      return _.map(dictionary, (item) => ({ name: item.name, name_ru: item.name_ru, code: item.code }));
    }
    const hintSource = form.getValues()?.sections[sectionIndex]?.fields[index]?.uiHintSource;
    if (hintSource && !_.isEmpty(hintSource)) {
      return hintSource;
    }
    return [];
  }, [dictionary, form, sectionIndex, index]);

  const dictionariesOptions = useMemo(() => {
    const customOption = { value: '', label: 'Valori personalizate' };
    const opts = _.map(dictionaries, (dict) => ({ value: dict.name, label: dict.name }));

    return [customOption, ...opts];
  }, [dictionaries]);

  return (
    <Paper withBorder p="md">
      <div className="flex space-x-2 items-center">
        <div className="flex flex-col w-full">
          <div className="flex space-x-2 items-center justify-between">
            <span className="text-gray-900 font-semibold text-sm">
              {t('titles.question-title', { number: index + 1 })}
            </span>
            <ActionIcon
              color="gray.1"
              className="text-gray-900"
              onClick={() => form.removeListItem(`sections.${sectionIndex}.fields`, index)}
            >
              <IconX className="size-4" />
            </ActionIcon>
          </div>
          <Group grow>
            <TextInput
              mt="md"
              classNames={{
                input:
                  'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
              }}
              label={t('labels.title')}
              withAsterisk
              placeholder={t('placeholders.question-title')}
              {...form.getInputProps(`sections.${sectionIndex}.fields.${index}.name`)}
            />
            <TextInput
              mt="md"
              classNames={{
                input:
                  'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
              }}
              label={`${t('labels.title')} (RU)`}
              withAsterisk
              placeholder={`${t('placeholders.question-title')} (RU)`}
              {...form.getInputProps(`sections.${sectionIndex}.fields.${index}.name_ru`)}
            />
          </Group>
          <Group grow>
            <TextInput
              mt="md"
              classNames={{
                input:
                  'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
              }}
              label={t('labels.description')}
              placeholder={t('placeholders.question-description')}
              {...form.getInputProps(`sections.${sectionIndex}.fields.${index}.description`)}
            />
            <TextInput
              mt="md"
              classNames={{
                input:
                  'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
              }}
              label={`${t('labels.description')} (RU)`}
              placeholder={`${t('placeholders.question-description')} (RU)`}
              {...form.getInputProps(`sections.${sectionIndex}.fields.${index}.description_ru`)}
            />
          </Group>

          <Select
            classNames={{
              input: `border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500 ${errorClasses}`,
            }}
            data={uiHintOptions}
            className="mt-4"
            label={t('labels.response-type')}
            {...form.getInputProps(`sections.${sectionIndex}.fields.${index}.ui_hint`)}
            placeholder={t('placeholders.response-type')}
            defaultValue={form.getValues().sections[sectionIndex].fields[index].ui_hint}
            onChange={(opt) => form.setFieldValue(`sections.${sectionIndex}.fields.${index}.ui_hint`, opt)}
          />

          {showOptions && (
            <>
              <Select
                classNames={{
                  input: `border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500 ${errorClasses}`,
                }}
                data={dictionariesOptions}
                className="mt-4"
                label={t('labels.dictionaries')}
                {...form.getInputProps(`sections.${sectionIndex}.fields.${index}.dictionary`)}
                placeholder={t('placeholders.dictionaries')}
                defaultValue={form.getValues().sections[sectionIndex].fields[index].dictionary ?? ''}
                allowDeselect={false}
                onChange={(opt) => {
                  form.setFieldValue(`sections.${sectionIndex}.fields.${index}.dictionary`, opt);
                  if (!opt || !_.isEmpty(opt)) {
                    form.setFieldValue(`sections.${sectionIndex}.fields.${index}.uiHintSource`, []);
                  }
                }}
              />
              <HintSourceInput
                options={options}
                showInput={!optionsEditDisabled}
                onAddItem={onAddOptionClick}
                onRemoveItem={removeResponseOption}
              />
            </>
          )}
          <Checkbox
            mt="md"
            color="indigo.6"
            label={t('labels.required-response')}
            {...form.getInputProps(`sections.${sectionIndex}.fields.${index}.is_required`, { type: 'checkbox' })}
          />
        </div>
      </div>
    </Paper>
  );
}

function HintSourceInput({ options, onAddItem, onRemoveItem, showInput }) {
  const { t } = useTranslation();
  const [option, setOption] = useState('');
  const [optionRu, setOptionRu] = useState('');
  const addOption = useCallback(() => {
    const optionsValid = !_.isEmpty(option) && !_.isEmpty(optionRu);
    if (optionsValid) {
      onAddItem({ name: option, name_ru: optionRu });
      setOption('');
      setOptionRu('');
    }
  }, [option, optionRu, onAddItem]);
  const rows = useMemo(() => {
    return _.map(options, (opt, index) => {
      return (
        <Table.Tr key={opt.name}>
          <Table.Td>{opt.name}</Table.Td>
          <Table.Td>{opt.name_ru}</Table.Td>
          <Table.Td>
            <ActionIcon
              disabled={!showInput}
              color="gray.1"
              className="text-gray-900"
              onClick={() => onRemoveItem(index)}
            >
              <IconX className="size-4" />
            </ActionIcon>
          </Table.Td>
        </Table.Tr>
      );
    });
  }, [options, onRemoveItem, showInput]);
  return (
    <div className="w-full mt-4 p-8 flex flex-col items-center space-y-4 rounded-md border border-gray-300">
      {showInput && (
        <Group grow className="w-full">
          <TextInput
            classNames={{
              input:
                'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
            }}
            label={t('labels.response-options')}
            placeholder={t('placeholders.add-option')}
            withAsterisk
            mt="md"
            value={option}
            onChange={(e) => setOption(e.target.value)}
          />
          <TextInput
            classNames={{
              input:
                'text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
            }}
            label={`${t('labels.response-options')} (RU)`}
            placeholder={`${t('placeholders.add-option')} (RU)`}
            withAsterisk
            mt="md"
            value={optionRu}
            onChange={(e) => setOptionRu(e.target.value)}
          />
        </Group>
      )}
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Ro</Table.Th>
            <Table.Th>Ru</Table.Th>
            <Table.Th className="w-10" />
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
      {showInput && (
        <Button
          variant="outline"
          onClick={addOption}
          className="w-min"
          leftSection={<IconPlus size={16} />}
          radius="xl"
          color="gray.5"
        >
          {t('btn.add-option')}
        </Button>
      )}
    </div>
  );
}
