import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IconArrowRight, IconArrowLeft } from '@tabler/icons-react';
import { Stepper, Spoiler } from '@mantine/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useQuestionnaireContext } from 'modules/questionnaire/hooks/useQuestionnaireContext';
import Button from '../button/Button';
import { RadioGroup, FieldSelect, CheckboxGroup, TextAreaField, RatingInput } from '../form';

export default function QuestionnaireSection({
  section,
  sectionIndex,
  totalSections,
  values,
  errors,
  onValuesChanged,
  onNextClick,
  onPreviousClick,
  onSubmit,
  isSubmitting,
}) {
  const { localeConfig } = useQuestionnaireContext();
  const { t } = useTranslation('translation', { lng: localeConfig.locale });
  const hasNext = sectionIndex + 1 < totalSections;
  const hasPrevious = sectionIndex > 0;
  const isFinalStep = sectionIndex + 1 === totalSections;
  const widthClasses = 'lg:max-w-screen-md md:max-w-[704px] sm:max-w-[576px]';
  const classes = 'mx-auto shadow-sm sm:rounded-xl border border-slate-200 bg-white h-fukk';
  const onChange = (field, value) => {
    onValuesChanged({ sectionKey: section.code, field, value });
  };

  const hasRightButton = hasNext || isFinalStep;
  const hasLeftButton = hasPrevious;
  return (
    <div className="max-h-full h-full sm:h-min sm:py-8">
      <div className={classNames(widthClasses, classes)}>
        <div className="bg-slate-50 p-6 sm:rounded-t-lg flex flex-col text-center min-[366px]:flex-row justify-between items-center border border-slate-200">
          <span>{t('questionnaire.step-count', { current: sectionIndex + 1, total: totalSections })}</span>
          <Stepper
            active={sectionIndex}
            allowNextStepsSelect={false}
            classNames={{
              steps: 'flex items-center justify-between space-x-4',
              separator: 'hidden',
            }}
            styles={{
              stepIcon: { border: 'none' },
            }}
            iconSize="10px"
            progressIcon={
              <div className="p-1.5 bg-emerald-200 rounded-full">
                <div className="bg-emerald-500 h-2.5 w-2.5 rounded-full" />
              </div>
            }
            completedIcon={<div className="bg-emerald-500 h-2.5 w-2.5 rounded-full" />}
            icon={<div className="bg-grey-200 w-full h-full rounded-full" />}
          >
            {_.times(totalSections, (i) => (
              <Stepper.Step key={i} />
            ))}
          </Stepper>
        </div>
        <div className="p-8">
          <SectionHeader title={section.name} description={section.description} />
          <SectionBody section={section} fields={section.fields} onChange={onChange} values={values} errors={errors} />
        </div>
        <SectionFooter
          className={classNames({
            'justify-between': hasLeftButton && hasRightButton,
            'justify-end': hasRightButton && !hasLeftButton,
          })}
        >
          {hasPrevious && (
            <Button
              onClick={onPreviousClick}
              color="default"
              variant="default"
              leftSection={<IconArrowLeft size={20} />}
              radius="xl"
              disabled={isSubmitting}
            >
              {t('btn.back')}
            </Button>
          )}
          {hasNext && (
            <Button
              onClick={() => onNextClick(values)}
              color="primary"
              rightSection={<IconArrowRight size={20} />}
              radius="xl"
              disabled={isSubmitting}
            >
              {t('btn.next')}
            </Button>
          )}
          {isFinalStep && (
            <Button onClick={onSubmit} color="primary" loading={isSubmitting} radius="xl">
              {t('btn.submit')}
            </Button>
          )}
        </SectionFooter>
      </div>
    </div>
  );
}

QuestionnaireSection.propTypes = {
  section: PropTypes.object.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  totalSections: PropTypes.number.isRequired,
  onNextClick: PropTypes.func,
  onPreviousClick: PropTypes.func,
  onValuesChanged: PropTypes.func,
  onSubmit: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
};
QuestionnaireSection.defaultProps = {
  onNextClick: _.noop,
  onPreviousClick: _.noop,
  onValuesChanged: _.noop,
  onSubmit: _.noop,
  isSubmitting: false,
  values: {},
  errors: {},
};

const isFieldVisible = (field, values) => {
  const { visible_dependence: visibilityCondition } = field;
  if (_.isNil(visibilityCondition) || _.isEmpty(visibilityCondition)) {
    return true;
  }
  const [dependencyField, dependencyValue] = _.split(visibilityCondition, ':');
  const val = values?.[dependencyField];
  if (_.isArray(val)) {
    return _.some(val, { code: dependencyValue });
  }
  return _.isEqual(val?.value, dependencyValue);
};
function SectionBody({ section, errors, fields, values, onChange }) {
  const { localeConfig } = useQuestionnaireContext();

  const { t } = useTranslation('translation', { lng: localeConfig.locale });
  return (
    <div className="flex flex-col flex-grow space-y-8 mt-8">
      {_.map(fields, (field) => {
        const { uiHint: fieldType, uiHintSource: fieldOptions, placeholder, code } = field;
        const isRadio = fieldType === 'RadioGroup';
        const isCheckbox = fieldType === 'CheckBox';
        const isSelect = fieldType === 'DropDownBox';
        const isRating = fieldType === 'Rating';
        const isTextArea = _.isNil(fieldType) || fieldType === 'TextBox';
        const isVisible = isFieldVisible(field, values);
        const fieldError = errors?.[code];
        return isVisible ? (
          <div className="flex flex-col space-y-2" key={`${section?.code}-${field.code}`}>
            <div className="space-y-1 flex flex-col mb-1">
              <span className="font-semibold text-grey-700 text-base">{field.name}</span>
              <Spoiler
                maxHeight="24"
                showLabel={t('labels.read-more')}
                hideLabel={t('labels.hide')}
                className="text-slate-500 text-sm"
                classNames={{ root: 'flex flex-col-reverse', control: 'self-start text-emerald-500' }}
                styles={{ control: { position: 'relative' } }}
              >
                <span className="text-slate-500 text-sm mb-4">{field.description}</span>
              </Spoiler>
            </div>
            {isRadio && (
              <RadioGroup
                options={fieldOptions}
                error={fieldError}
                onChange={(val) => onChange(field, val)}
                value={values?.[code]}
              />
            )}
            {isSelect && (
              <div className="sm:max-w-[312px]">
                <FieldSelect
                  options={fieldOptions}
                  placeholder={placeholder}
                  onChange={(_value, option) => onChange(field, option)}
                  value={values?.[code]}
                  error={fieldError}
                />
              </div>
            )}
            {isCheckbox && (
              <CheckboxGroup
                error={fieldError}
                options={fieldOptions}
                onChange={(val) => onChange(field, val)}
                values={values?.[code]}
              />
            )}
            {isTextArea && (
              <TextAreaField
                onChange={(val) => {
                  onChange(field, val);
                }}
                error={fieldError}
                value={values?.[code]}
              />
            )}
            {isRating && (
              <RatingInput
                error={fieldError}
                onChange={(val) => onChange(field, val)}
                options={fieldOptions}
                value={values?.[code]}
              />
            )}
          </div>
        ) : null;
      })}
    </div>
  );
}
SectionBody.propTypes = {
  fields: PropTypes.array,
  section: PropTypes.object,
  values: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
};
SectionBody.defaultProps = {
  fields: [],
  section: {},
  values: {},
  errors: {},
  onChange: _.noop,
};
function SectionHeader({ title, description }) {
  return (
    <div className="flex flex-col">
      <div className="text-slate-900 font-semibold text-2xl">{title}</div>
      <div className="text-slate-500 text-base">{description}</div>
      <hr className="border-t border-slate-200 mt-8" />
    </div>
  );
}
SectionHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
SectionHeader.defaultProps = {
  title: '',
  description: '',
};
function SectionFooter({ children, className }) {
  return (
    <div>
      <hr className="border-t border-slate-200 mt-8" />
      <div className={classNames('flex flex-col min-[366px]:flex-row p-8 min-[366px]:space-y-0 space-y-2', className)}>
        {children}
      </div>
    </div>
  );
}
SectionFooter.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

SectionFooter.defaultProps = {
  className: '',
};

QuestionnaireSection.Footer = SectionFooter;
QuestionnaireSection.Body = SectionBody;
QuestionnaireSection.Header = SectionHeader;
