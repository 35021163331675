import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { IconGuestAdd, IconAccessabilityCheck, IconPersonHeart, IconBuilding } from 'components/icons';

import classNames from 'classnames';
import { useGetPublicTranslation } from 'api/hooks';
import _ from 'lodash';

const icons = [IconPersonHeart, IconBuilding, IconAccessabilityCheck, IconGuestAdd];
const ExplanationContainer = forwardRef(function Explanation({ className }, ref) {
  const { translation: title } = useGetPublicTranslation({ resourceKey: 'Landing', key: 'ExplanationTitle' });

  return (
    <div
      ref={ref}
      className={classNames(className, 'flex flex-col items-center justify-center bg-white py-16 space-y-10')}
    >
      <div className="flex flex-col md:items-center md:justify-center md:text-center space-y-6 md:w-2/3">
        <span className="text-3xl md:text-4xl font-bold text-zinc-900">{title?.text}</span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-7">
        {_.map(_.range(4), (i) => (
          <ExplanationGridItem key={`explanationItem[${i}]`} index={i} icon={icons[i]} />
        ))}
      </div>
    </div>
  );
});

function ExplanationGridItem({ index, icon: Icon }) {
  const { translation: title } = useGetPublicTranslation({
    resourceKey: 'Landing',
    key: `ExplanationItem[${index}]Title`,
  });
  const { translation: description } = useGetPublicTranslation({
    resourceKey: 'Landing',
    key: `ExplanationItem[${index}]Description`,
  });
  return (
    <div className="flex flex-col items-center space-y-2 bg-slate-100 p-6 rounded-md">
      
      <div className="flex-none rounded bg-sky-800  rounded-xl size-[72px] p-3 text-white">
        <Icon className="w-full h-full" />
      </div>
      <span className="text-lg font-semibold text-xl">{title?.text}</span>
      <span className="text-base text-center font-normal text-gray-500">{description?.text}</span>
    </div>
  );
}

export default ExplanationContainer;

ExplanationContainer.propTypes = {
  className: PropTypes.string,
};
ExplanationContainer.defaultProps = {
  className: '',
};

ExplanationGridItem.propTypes = {
  index: PropTypes.number.isRequired,
  icon: PropTypes.any.isRequired,
};
