/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useRef, useMemo, useEffect } from 'react';
import { useForm, isEmail, hasLength, isNotEmpty } from '@mantine/form';
import { Button, Group, Text, Textarea, Paper, Flex, Select, rem } from '@mantine/core';
import { LocationSelect } from 'components/fields';
import { IconArrowLeft, IconUpload, IconPhoto, IconX, IconCloudUpload, IconDownload } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Dropzone, DropzoneProps, PDF_MIME_TYPE } from '@mantine/dropzone';

export default function ContentForm({ translation, onSubmit, isSubmitting, onBackClick, visibilityClasses }) {
  const { t } = useTranslation();

  const translationValues = useMemo(() => {
    const merged = [_.pick(translation, ['language', 'text']), ...(translation?.mui ?? [])];
    return _.uniqBy(merged, 'language');
  }, [translation]);

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      key: translation?.key,
      resourceKey: translation?.resourceKey,
      languages: translationValues ?? [],
      type: translation?.type ?? 'Text',
    },
    validate: {
      languages: {
        text: hasLength({ min: 3, max: 1000 }, 'Institution Name must be 3-1000 characters long'),
      },
    },
    transformValues: (values) => {
      const defaultValue = _.find(values.languages, ['language', 'ro']);
      const submitValues = {
        ...values,
        isHtml: false,
        language: defaultValue.language,
        text: defaultValue.text,
        mui: _.reject(values.languages, ['language', 'ro']),
      };
      return submitValues;
    },
  });

  const types = useMemo(() => {
    return [
      { type: 'Text', label: t('text'), value: 'Text' },
      { type: 'File', label: t('file'), value: 'File' },
    ];
  }, [t]);

  return (
    <div className={`p-4 ${visibilityClasses}`}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <div className="flex flex-col space-y-2 mb-4">
          <Select
            classNames={{
              input: `border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500`,
            }}
            disabled
            data={types}
            label={t('forms.select-translation-type')}
            placeholder={t('forms.select-translation-type')}
            allowDeselect={false}
            {...form.getInputProps(`type`)}
            onChange={(val) => {
              form.setFieldValue('type', val);
            }}
          />
        </div>
        {form.getValues().type === 'Text' && (
          <Paper withBorder p="md" shadow="md">
            <div className="grid grid-cols-2 gap-4">
              {form.getValues().languages.map((item, index) => {
                return <ContentLanguageContainer key={item.language} form={form} index={index} />;
              })}
            </div>
          </Paper>
        )}
        {form.getValues().type === 'File' && (
          <Paper withBorder p="md" shadow="md">
            <div className="grid grid-cols-2 gap-4">
              {form.getValues().languages.map((language, index) => {
                return <ContentFileContainer form={form} index={index} />;
              })}
            </div>
          </Paper>
        )}

        <Group justify="space-between" className="mt-7">
          <Button
            variant="outline"
            leftSection={<IconArrowLeft size={16} />}
            radius="xl"
            color="gray.5"
            onClick={onBackClick}
          >
            {t('btn.back')}
          </Button>
          <Button radius="xl" color="indigo.6" type="submit" loading={isSubmitting}>
            {t('btn.apply')}
          </Button>
        </Group>
      </form>
    </div>
  );
}
ContentForm.propTypes = {
  translation: PropTypes.object,
  onSubmit: PropTypes.func,
  onBackClick: PropTypes.func,
  isSubmitting: PropTypes.bool,
  visibilityClasses: PropTypes.string,
};
ContentForm.defaultProps = {
  translation: null,
  onSubmit: _.noop,
  onBackClick: _.noop,
  isSubmitting: false,
  visibilityClasses: '',
};

function ContentLanguageContainer({ form, index }) {
  const { t } = useTranslation();
  const inputLabel = _.toUpper(form.getValues().languages?.[index]?.language);
  return (
    <div>
      <Textarea
        classNames={{
          input:
            'min-h-44 text-gray-900 border border-slate-300 shadow-sm focus:ring-1 focus:border-indigo-600 focus:ring-indigo-600  data-[error=true]:border-red-500',
        }}
        label={inputLabel}
        withAsterisk
        {...form.getInputProps(`languages.${index}.text`)}
      />
    </div>
  );
}

function ContentFileContainer({ form, index }) {
  const { t } = useTranslation();
  const inputLabel = _.toUpper(form.getValues().languages?.[index]?.language);
  const openRef = useRef(null);

  return (
    <div className="flex flex-col space-y-2">
      <div className="font-semibold">
        {inputLabel} <span className="text-red-500 text-lg">*</span>
      </div>
      <div className="mb-4">
        <Dropzone
          openRef={openRef}
          onDrop={() => {}}
          className="border-1 pb-4 border-slate-300"
          radius="md"
          accept={[PDF_MIME_TYPE]}
          maxSize={30 * 1024 ** 2}
          maxFiles={1}
          multiple={false}
        >
          <div style={{ pointerEvents: 'none' }}>
            <Group justify="center">
              <Dropzone.Accept>
                <IconDownload style={{ width: rem(50), height: rem(50) }} color="indigo.6" stroke={1.5} />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX style={{ width: rem(50), height: rem(50) }} color="red.5" stroke={1.5} />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconCloudUpload style={{ width: rem(50), height: rem(50) }} stroke={1.5} />
              </Dropzone.Idle>
            </Group>

            <Text ta="center" fw={700} fz="lg" mt="md">
              <Dropzone.Accept>Adăugați fișierul aici</Dropzone.Accept>
              <Dropzone.Reject>Fișierul .pdf nu trebuie să fie mai mare de 30mb</Dropzone.Reject>
              <Dropzone.Idle>Adăugați fișier</Dropzone.Idle>
            </Text>
            <Text ta="center" fz="sm" mt="xs" c="dimmed">
              Mutați fișierul aici. Fișierul trebuie să fie în format <i>.pdf</i> și nu mai mare de 30mb
            </Text>
          </div>
        </Dropzone>

        <div className="flex justify-center py-2 items-center">
          <Button variant="outline" color="gray.5" radius="xl" onClick={() => openRef.current?.()}>
            {t('btn.select-file')}
          </Button>
        </div>
      </div>
    </div>
  );
}
