export const queryKeys = {
  questionnaire: (id, locale) => ['questionnaire', id, locale],
  locations: ({ locale = 'ro' }) => ['locations', locale, localStorage.getItem('localeCode') ?? 'ro'],
  institutions: ({ locationCode, locale = 'ro' }) => [
    'institutions',
    locationCode,
    locale,
    localStorage.getItem('localeCode') ?? 'ro',
  ],
  institutionCategories: ({ locale = 'ro' }) => [
    'institutionCategories',
    locale,
    localStorage.getItem('localeCode') ?? 'ro',
  ],
  institutionSections: ({ locale = 'ro' }) => [
    'institutionSections',
    locale,
    localStorage.getItem('localeCode') ?? 'ro',
  ],
  ageGroups: ({ locale = 'ro' }) => ['ageGroups', locale, localStorage.getItem('localeCode') ?? 'ro'],
  dashboardTrends: (params) => ['dashboardTrends', params, localStorage.getItem('localeCode') ?? 'ro'],
  dashboardStatsData: (params) => ['dashboardStatsData', params, localStorage.getItem('localeCode') ?? 'ro'],
  reportsTrends: (params) => ['reportsTrends', params, localStorage.getItem('localeCode') ?? 'ro'],
  reports: (params) => ['reports', params, localStorage.getItem('localeCode') ?? 'ro'],
  report: (id) => ['report', id, localStorage.getItem('localeCode') ?? 'ro'],
  generalImpressions: () => ['generalImpressions', localStorage.getItem('localeCode') ?? 'ro'],
  user: (id) => ['user', id, localStorage.getItem('localeCode') ?? 'ro'],
  loggedInUser: (id) => ['loggedInUser', id, localStorage.getItem('localeCode') ?? 'ro'],
  users: (params) => ['users', params, localStorage.getItem('localeCode') ?? 'ro'],
  dashboardStats: (statsKey, period, institution) => [
    'dashboardStats',
    institution,
    statsKey,
    period,
    localStorage.getItem('localeCode') ?? 'ro',
  ],
  roles: () => ['rolesList', localStorage.getItem('localeCode') ?? 'ro'],
  nodes: (params) => ['nodes', params, localStorage.getItem('localeCode') ?? 'ro'],
  node: (id) => ['node', id],
  questionnaires: (params) => ['questionnaires', params, localStorage.getItem('localeCode') ?? 'ro'],
  questionnaireMetadata: (id) => ['questionnaireMetadata', id, localStorage.getItem('localeCode') ?? 'ro'],
  dictionary: (dictionary) => ['dictionary', dictionary, localStorage.getItem('localeCode') ?? 'ro'],
  dictionaries: () => ['dictionaries', localStorage.getItem('localeCode') ?? 'ro'],
  translations: () => ['translations', localStorage.getItem('localeCode') ?? 'ro'],
  multipleTranslations: (keys) => ['translations', ...keys, localStorage.getItem('localeCode') ?? 'ro'],
  translation: (resourceKey, key) => ['translation', resourceKey, key, localStorage.getItem('localeCode') ?? 'ro'],
  publicTranslation: (locale) => ['translation', 'public', locale, localStorage.getItem('localeCode') ?? 'ro'],
  file: (fileName) => ['file', fileName, localStorage.getItem('localeCode') ?? 'ro'],
};
