import { useQuery, useQueryClient } from 'react-query';
import { useCallback } from 'react';
import { getToken } from 'storage';
import _ from 'lodash';
import { useLocale } from 'locale/hooks';
import { queryKeys } from '../keys';
import { api } from '../api';

export const useQuestionnaire = ({ id, locale }, options) => {
  const { data, ...rest } = useQuery(queryKeys.questionnaire(id), async () => api.getQuestionnaire({ id, locale }), {
    ...options,
  });
  return { questionnaire: data, ...rest };
};

export const useLocations = ({ locale }, options) => {
  const { data, ...rest } = useQuery(queryKeys.locations({ locale }), async () => api.getLocations(), {
    ...options,
  });
  return { locations: data, ...rest };
};

export const useInstitutions = ({ locale, locationCode }, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.institutions({ locationCode, locale }),
    async () => api.getInstitutions({ locationCode, locale }),
    {
      ...options,
    },
  );
  return { institutions: data, ...rest };
};
export const useInstitutionCategories = ({ locale }, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.institutionCategories({ locale }),
    async () => api.getInstitutionCategory({ locale }),
    {
      ...options,
    },
  );
  return { categories: data, ...rest };
};
export const useAgeGroups = ({ locale }, options) => {
  const { data, ...rest } = useQuery(queryKeys.ageGroups({ locale }), async () => api.getAgeGroups({ locale }), {
    ...options,
  });
  return { ageGroups: data, ...rest };
};
export const useInstitutionSections = ({ locale }, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.institutionSections({ locale }),
    async () => api.getInstitutionSections({ locale }),
    {
      ...options,
    },
  );
  return { institutionSections: data, ...rest };
};
export const useDashboardTrends = (params, options) => {
  const { data, ...rest } = useQuery(queryKeys.dashboardTrends(params), async () => api.getDashboardTrends(params), {
    ...options,
  });
  return { stats: data, ...rest };
};
export const useDashboardStats = (params, options) => {
  const { data, ...rest } = useQuery(queryKeys.dashboardStatsData(params), async () => api.getDashboardStats(params), {
    ...options,
  });
  return { stats: data, ...rest };
};

export const useReportsTrends = (params, options) => {
  const { data, ...rest } = useQuery(queryKeys.reportsTrends(params), async () => api.getReportsTrends(params), {
    ...options,
  });
  return { reportsTrends: data, ...rest };
};
export const useReports = (params, options) => {
  const { data, ...rest } = useQuery(queryKeys.reports(params), async () => api.getReports(params), {
    ...options,
  });
  return { reports: data, ...rest };
};
export const useReport = (id, options) => {
  const { data, ...rest } = useQuery(queryKeys.reports(id), async () => api.getReport(id), {
    ...options,
  });
  return { report: data, ...rest };
};
export const useGeneralImpressions = (options) => {
  const { data, ...rest } = useQuery(queryKeys.generalImpressions(), async () => api.getGeneralImpressions(), {
    ...options,
  });
  return { impressions: data, ...rest };
};

export const useGetLoggedInUser = (options) => {
  const queryClient = useQueryClient();
  const resetUser = useCallback(() => {
    queryClient.resetQueries(queryKeys.loggedInUser(getToken()));
  }, [queryClient]);
  const { data, ...rest } = useQuery(queryKeys.loggedInUser(getToken()), async () => api.getCurrentUser(), {
    ...options,
  });
  return { user: data, resetUser, ...rest };
};
export const useGetUser = (id, options) => {
  const queryClient = useQueryClient();
  const resetUser = useCallback(() => {
    queryClient.resetQueries(queryKeys.user(id));
  }, [queryClient, id]);
  const { data, ...rest } = useQuery(queryKeys.user(id), async () => api.getUser({ id }), {
    ...options,
  });
  return { user: data, resetUser, ...rest };
};

export const useGetUsers = (params, options) => {
  const { data, ...rest } = useQuery(queryKeys.users(params), async () => api.getUsers(params), {
    ...options,
  });
  return { users: data, ...rest };
};
export const useRoles = (options) => {
  const { data, ...rest } = useQuery(queryKeys.roles(), async () => api.getRoles(), {
    ...options,
  });
  return { roles: data, ...rest };
};

export const useGetNodes = (params, options) => {
  const { data, ...rest } = useQuery(queryKeys.nodes(params), async () => api.getNodes(params), {
    ...options,
  });
  return { nodes: data, ...rest };
};

export const useGetNode = (id, options) => {
  const { data, ...rest } = useQuery(queryKeys.node(id), async () => api.getNode({ id }), {
    ...options,
  });
  return { node: data, ...rest };
};

export const useQuestionnaires = (params, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.questionnaires(params),
    async () => api.getQuestionnaires(params ?? {}),
    {
      ...options,
    },
  );
  return { questionnaires: data?.dictionaries, ...rest };
};
export const useQuestionnaireMetadata = (id, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.questionnaireMetadata(id),
    async () => api.getQuestionnaireMetadata({ id }),
    {
      ...options,
    },
  );
  return { questionnaire: data, ...rest };
};

export const useDictionary = (dictionary, options) => {
  const { enabled, ...other } = options ?? {};
  const { data, ...rest } = useQuery(queryKeys.dictionary(dictionary), async () => api.getDictionary({ dictionary }), {
    enabled: Boolean(dictionary) && enabled,
    ...other,
  });
  return { dictionary: data, ...rest };
};

export const useDictionaries = (options) => {
  const { data, ...rest } = useQuery(queryKeys.dictionaries(), async () => api.getDictionaries(), {
    ...options,
  });
  return { dictionaries: data, ...rest };
};

export const useGetTranslations = (params, options) => {
  const { data, ...rest } = useQuery(queryKeys.translations(params), async () => api.getTranslations(params ?? {}), {
    ...options,
  });
  return { translations: data, ...rest };
};

export const useGetMultipleTranslations = (params, options) => {
  const { keys = [], ...restParams } = params;
  const { data, ...rest } = useQuery(
    queryKeys.multipleTranslations(keys),
    async () => Promise.all(keys.map((key) => api.getTranslation({ ...restParams, key }))),
    {
      ...options,
    },
  );
  return { translations: data, ...rest };
};

export const useGetPublicTranslation = ({ resourceKey, key }, options) => {
  const { currentLocale } = useLocale();
  const { data, ...rest } = useQuery(
    queryKeys.publicTranslation(currentLocale.key),
    async () => api.publicTranslations(currentLocale.key),
    {
      ...options,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const transformed = _.reduce(
    data,
    (result, value, translationKey) => {
      const globalKey = _.split(translationKey, '.')?.[0];
      const localKey = _.split(translationKey, '.')?.[1];
      if (result[globalKey]) {
        result[globalKey].push({ key: localKey, text: value });
      } else {
        // eslint-disable-next-line no-param-reassign
        result[globalKey] = [{ key: localKey, text: value }];
      }
      return result;
    },
    {},
  );
  if (_.isArray(key)) {
    const filteredTranslations = _.filter(transformed?.[resourceKey], (item) => _.includes(key, item.key));
    return { translation: filteredTranslations, ...rest };
  }
  if (_.isRegExp(key)) {
    const filteredTranslations = _.filter(transformed?.[resourceKey], (item) => {
      return key.test(item.key);
    });
    return { translation: filteredTranslations, ...rest };
  }
  const foundTranslation = _.find(transformed?.[resourceKey], ['key', key]);
  return { translation: foundTranslation, ...rest };
};
export const useGetTranslation = ({ resourceKey, key }, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.translation(resourceKey, key),
    async () => api.getTranslation({ resourceKey, key }),
    {
      ...options,
    },
  );
  return { translation: data, ...rest };
};

export const useGetFile = ({ fileName }, options) => {
  const { data, ...rest } = useQuery(queryKeys.file(fileName), async () => api.downloadFile({ fileName }), {
    ...options,
  });
  return { file: data, ...rest };
};
