/* eslint-disable no-unused-vars */
import { AppShell } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Routes, Route } from 'react-router-dom';
import { SideBar, Navbar, PermissionGuard } from 'components';
import { useAuthContext, roles } from 'auth';
import { useHasRole } from 'auth/hooks/useHasRole';
import Dashboard from './Dashboard';
import AppSplash from './AppSplash';
import Reports from './Reports';
import Users from './Users';
import ProfileSettings from './ProfileSettings';
import Institutions from './Institutions';
import { Questionnaires } from './questionnaire';
import Content from './Content';

const adminRoutes = (
  <>
    <Route exact path="/dashboard" element={<Dashboard />} />
    <Route exact path="/reports" element={<Reports />} />
    <Route exact path="/reports/:page" element={<Reports />} />
    <Route exact path="/users" element={<Users />} />
    <Route exact path="/users/:page" element={<Users />} />
    <Route exact path="/institutions" element={<Institutions />} />
    <Route exact path="/institutions/:page" element={<Institutions />} />
    <Route exact path="/questionnaires" element={<Questionnaires />} />
    <Route exact path="/questionnaires/:page" element={<Questionnaires />} />
    <Route exact path="/content" element={<Content />} />
    <Route exact path="/content/:page" element={<Content />} />
    <Route exact path="/profile" element={<ProfileSettings />} />
  </>
);
const defaultRoutes = (
  <>
    <Route exact path="/dashboard" element={<Dashboard />} />
    <Route exact path="/reports" element={<Reports />} />
    <Route exact path="/reports/:page" element={<Reports />} />
    <Route exact path="/profile" element={<ProfileSettings />} />
  </>
);
export default function MainContainer() {
  const [opened, { toggle }] = useDisclosure();
  const { isLoggedIn } = useAuthContext();
  const isAdmin = useHasRole([roles.admin]);

  return (
    <AppShell
      header={{ height: 60 }}
      classNames={{ main: 'flex' }}
      navbar={{ width: 255, breakpoint: '800px', collapsed: { mobile: !opened } }}
    >
      <AppShell.Header withBorder={false}>
        <Navbar opened={opened} toggle={toggle} />
      </AppShell.Header>
      {isLoggedIn && (
        <>
          <AppShell.Navbar className="py-5 px-2">
            <SideBar />
          </AppShell.Navbar>
          <AppShell.Main>
            <Routes>
              {isAdmin ? adminRoutes : defaultRoutes}
              {/* <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/reports" element={<Reports />} />
              <Route exact path="/reports/:page" element={<Reports />} />
              <Route exact path="/users" element={<Users />} />
              <Route exact path="/users/:page" element={<Users />} />
              <Route exact path="/institutions" element={<Institutions />} />
              <Route exact path="/institutions/:page" element={<Institutions />} />
              <Route exact path="/questionnaires" element={<Questionnaires />} />
              <Route exact path="/questionnaires/:page" element={<Questionnaires />} />
              <Route exact path="/content" element={<Content />} />
              <Route exact path="/content/:page" element={<Content />} />
              <Route exact path="/profile" element={<ProfileSettings />} /> */}
            </Routes>
          </AppShell.Main>
        </>
      )}
      {!isLoggedIn && <AppSplash />}
    </AppShell>
  );
}
