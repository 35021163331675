import { useCallback, useMemo } from 'react';
import { Rating } from '@mantine/core';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default function RatingInput({ options, error, value, onChange }) {
  const ratingCount = _.size(options);

  const selectedOption = useMemo(() => {
    return _.find(options, (o) => o.code === value);
  }, [options, value]);
  const onRatingSelect = useCallback(
    (val) => {
      const optionValue = _.find(options, (o) => {
        return o.name === `${val}`;
      });
      onChange(optionValue?.code);
    },
    [options, onChange],
  );

  return (
    <div className="flex flex-col">
      <Rating
        classNames={{ root: 'space-x-2' }}
        size="33px"
        color="amber.4"
        value={selectedOption?.name ?? 0}
        onChange={onRatingSelect}
        count={ratingCount}
      />
      {!_.isEmpty(error) && <p className="text-red-600 text-xs mt-2">{error} </p>}
    </div>
  );
}

RatingInput.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
  error: PropTypes.string,
};

RatingInput.defaultProps = {
  onChange: _.noop,
  value: 0,
  error: '',
};
